<template>

	<div v-if="!user">
		<h2 class="text-2xl">Login Required</h2>
		<p>You need to be logged in to use vods.</p>
	</div>

  <div v-else>

    <div class="bg-black bg-opacity-25 w-full p-10">
      <div class="flex justify-end">
        <div class="flex-grow">
          <h1 class="text-2xl">Stream Offline</h1>
          <p>Stats will show up here when you go live.</p>
        </div>
        <div class="text-right">
          <span class="text-right text-4xl">5000</span>
        </div>
      </div>
    </div>

    <div class="bg-black bg-opacity-25 w-full p-5 mt-5">
      <StreamEventList/><br>
    </div>

    <div class="bg-black bg-opacity-25 w-full p-5 mt-5">
      <StreamScheduleList/><br>
    </div>


  </div>

</template>

<script>
import svgIcon from "@jamescoyle/vue-icon";
import DynamicIcon from "@/components/dynamic_icon.vue";
import StreamEventList from "@/components/streamsuite/stream_event_list.vue";
import StreamScheduleList from "@/components/streamsuite/stream_schedule_list.vue";

export default {
	name: 'VodList',
	components: {
    StreamScheduleList,
    StreamEventList,
		DynamicIcon,
		svgIcon,
	},
	async mounted() {

    this.$store.commit('setTitle', 'Stream Suite');

  },
	methods: {
	},
	computed:{
    user(){
      return this.$store.state.user
    },
	}
}
</script>
<style scoped lang="less">

@import '@/assets/less/theme.less';

</style>
