<template>

  <div class="recent-events">
    <div class="flex justify-end">
      <div class="flex-grow">
        <h1 class="text-2xl">Scheduled Streams</h1>
        <p>Let your viewers know when you'll be live, so they can be there to support you.</p><br>
      </div>
      <div>
        <button class="chip text-right" @click="scheduleNewStream">Schedule new stream</button>
      </div>
    </div>
    <grid ref="streamgrid" endpoint="streamsuite/scheduled_stream" :mode="'grid grid-cols-1 gap-2'">
      <template #items="props">
        <StreamScheduleItem v-for="stream in props.list" :key="stream.id" :stream="stream"/>
      </template>
    </grid>
  </div>

</template>

<script>

import {promptModal} from "jenesius-vue-modal";
import CreateToken from "@/components/modals/CreateToken.vue";
import ScheduleNewStream from "@/components/modals/ScheduleNewStream.vue";
import StreamScheduleItem from "@/components/streamsuite/streamschedule.vue";
import Grid from "@/components/grid/Grid.vue";

export default {
  name: 'StreamScheduleList',
  components: {
    Grid,
    StreamScheduleItem

  },
  data: () => ({
    streams: []
  }),
  mounted() {
    // Add event listener when component mounts
    window.addEventListener('dispatch:streamsuite_scheduled_stream', this.handleCustomSocketEvent);
    this.getRecentActions();

  },
  beforeUnmount() {
    // Remove event listener before the component is unmounted to prevent memory leaks
    window.removeEventListener('dispatch:streamsuite_scheduled_stream', this.handleCustomSocketEvent);
  },
  methods: {
    handleCustomSocketEvent(event) {
      this.getRecentActions();
    },
    async getRecentActions() {
      const response = await this.$api.v1.get(`streamsuite/scheduled_stream`);
      if(response?.data?.data){
        this.streams = response.data.data;
      }
    },
    async scheduleNewStream() {
      const modal = await promptModal(ScheduleNewStream);
    }
  }

}
</script>