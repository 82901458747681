<template>

  <BaseModelStyle class="w-full" style="max-width: 800px;">

    <h1 class="text-2xl">Schedule New Stream</h1>

    <div class="grid grid-cols-1 gap-5">

      <div>
        <label for="time">When do you plan to go live?</label>
        <input name="time" ref="input" v-model="time" type="datetime-local" class="p-2 w-full">
      </div>

      <div>
        <label>What will you be doing?</label>
        <ApiSelect endpoint="cache/games" v-model="game" @change="changeGame">
          <template v-slot:item="{ item, selectItem }">
            <li
                @click="selectItem(item)"
                class="text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-gray-100"
            >
              <img :src="item.box_art_url" alt="Game Box Art" class="w-8 h-8 mr-3 inline">
              {{ item.title }}
            </li>
          </template>
        </ApiSelect>
      </div>

      <div>
        <label for="title">Title your stream</label>
        <input name="title" ref="title" v-model="title" type="text" class="p-2 w-full">
      </div>

      <div>
        <label for="description">Anything you want your viewers to know? (description)</label>
        <textarea name="description" v-model="description" class="block w-full text-black" style="min-height: 150px;">
        </textarea>
      </div>

      <div>
        <label for="publish">Publish schedule?</label><br>
        <!-- large checkbox -->
        <input name="publish" v-model="publish" type="checkbox" class="p-5 w-full h-8"/><br>

        <i>Publishing a schedule will make it visible on all your connected accounts</i><br>

      </div>

    </div>

    <h2 class="text-2xl mt-5">Preview</h2>
    <div class="box p-5 w-full">

      <div class="flex gap-5">

        <div style=" max-width: 380px;">
          <img :src="game?.box_art_url || 'https://picsum.photos/285/380'" alt="Placeholder" class="w-full" style="max-width: 150px;">
        </div>

        <div class="flex-grow">
          <p class="text-2xl">{{ title }}</p>
          <p class="bold">{{ game?.title }}</p>
          <p v-if="time" class="italic">{{ DateTime.fromFormat(time, "yyyy-MM-dd'T'hh:mm").toLocaleString() }}</p>
          <p class="break-words whitespace-pre-wrap w-full overflow-hidden">{{ description }}</p>

          <p v-if="publish" class="bold italic mt-2">Posted by {{user.username}} on {{ displayed_publish_time }}</p>
        </div>

      </div>

    </div>

    <br>
    <button @click="doImport" class="chip">Schedule</button>

  </BaseModelStyle>

</template>
<script>
import {Modal} from "jenesius-vue-modal";
import BaseModelStyle from "@/components/BaseModelStyle.vue";
import {DateTime} from "luxon";
import ApiSelect from "@/components/input/apiselect.vue";

export default {
  name: 'ScheduleNewStream',
  components: {ApiSelect, BaseModelStyle},
  data: () => ({
    time: DateTime.now().set({ minutes: 0, hours: 0, }).toFormat("yyyy-MM-dd'T'hh:mm"),
    game: null,
    title: '',
    description: '',
    publish: false,
    displayed_publish_time: '',
  }),
  mounted() {
    this.$refs.input.focus();
    setInterval(() => {
      this.displayed_publish_time = DateTime.now().toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
    }, 1000);
  },
  computed: {
    DateTime() {
      return DateTime
    },
    user() {
      return this.$store.state.user;
    }
  },
  methods: {
    doImport() {

      this.$api.v1.post('streamsuite/scheduled_stream', {
        time: this.time,
        game: this.game.title,
        title: this.title,
        description: this.description,
        publish: this.publish ? 1 : 0,
      }).then((response) => {
        this.$emit(Modal.EVENT_PROMPT, response.data);
      }).catch((error) => {
        this.$notify.error(error);
      });

    },
    changeGame(game) {
      console.log(game);
      this.game = game;
    }
  }
}
</script>