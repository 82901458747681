<template>
  <div class="relative inline-block w-full">
    <input
        type="text"
        v-model="searchQuery"
        @focus="isOpen = true"
        @blur="closeDropdown"
        placeholder="Search for a game..."
        class="form-input block w-full pl-3 pr-10 py-2 leading-5 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
    />
    <ul v-if="isOpen && items.length" class="absolute z-10 mt-1 bg-white shadow-lg max-h-56 rounded-md py-1 text-base leading-6 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm sm:leading-5">
      <slot name="item" v-for="item in items" :key="item.id" :item="item" :selectItem="selectItem" />
    </ul>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    endpoint: {
      type: String,
      required: true,
    },
    value: String,
  },
  data() {
    return {
      isOpen: false,
      searchQuery: this.value?.title || '', // Initialize with title if value is an object
      items: [],
      selectedItem: null,
    };
  },
  watch: {
    async searchQuery(newQuery) {
      if (newQuery.length >= 3) {
        await this.fetchItems(newQuery);
      } else {
        this.items = [];
      }
    },
    value(newValue) {
      this.searchQuery = newValue?.title || ''; // Update when v-model changes
    },
  },
  methods: {
    async fetchItems(query) {
      try {
        // Adjust this call according to your actual API integration
        const response = await this.$api.v1.get(`${this.endpoint}?search=${query}`);
        this.items = response.data.data || []; // Adjust based on your API response structure
      } catch (error) {
        console.error("Error fetching items:", error);
        this.items = [];
      }
    },
    selectItem(item) {
      console.log('Selected item:', item);
      this.searchQuery = item.title;
      this.selectedItem = item;

      // Emit the item for v-model compatibility
      this.$emit('input', item);
      this.$emit('change', item);
      this.isOpen = false;
    },
    closeDropdown() {
      setTimeout(() => {
        this.isOpen = false;
      }, 200);
    },
  },
};
</script>
