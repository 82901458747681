import {
    mdiFile,
    mdiLink,
    mdiPresentation,
    mdiPassport,
    mdiSquareRoundedBadge,
    mdiVideo,
    mdiHome,
    mdiBug,
    mdiRecordRec, mdiPencil
} from "@mdi/js";

export function apiTypeToIcon(result){
    switch (result['_search_type']) {
        case "file": return mdiFile;
        case "character": return mdiPassport;
        case "streamsuite": return mdiVideo;
        default : return mdiBug;
    }
}
export function apiTypeToTitle(result){
    switch (result['_search_type']) {
        case "file": return result.filename;
        case "character": return result.name;
        case "streamsuite_highlight": return result.title;
        default : return result['_search_type'];
    }
}

export function apiSearchResultToNavItem(result){

    let obj =  {
        name: apiTypeToTitle(result),
        icon: apiTypeToIcon(result),
    }

    switch (result['_search_type']) {
        case "file": obj.link = `/file/${result.id}`; break;
        case "character": obj.link = `/character/${result.id}`; break;
        case "streamsuite_highlight": obj.link = `/streamsuite/highlight/${result.id}`; break;
    }

    return obj;

}

export default function getNavItems(user){

    let items = [];

    items.push({
        name: 'Home',
        icon: mdiHome,
        link: '/',
    });

    if(user){

        items.push({
            name: 'Files',
            terms: ['download', 'upload'],
            icon: mdiFile,
            link: '/files',
        });

        items.push({
            name: 'URL Shortener',
            terms: ['to', 'links'],
            icon: mdiLink,
            link: '/links',
        });

        // items.push({
        //     name: 'Characters',
        //     icon: mdiPassport,
        //     link: '/characters',
        // });
        //
        // items.push({
        //     name: 'Whiteboards',
        //     icon: mdiPresentation,
        //     link: '/boards',
        // });

        items.push({
            name: 'Streamsuite',
            terms: ['vod', 'vods', 'history', 'streamsuite'],
            icon: mdiRecordRec,
            link: '/streamsuite',
        });

        items.push({
            name: 'Write',
            icon: mdiPencil,
            link: '/write',
        });

    }

    items.push({
        name: 'Apps & Bots',
        terms: ['bot', 'bots', 'app', 'watermelon'],
        icon: mdiSquareRoundedBadge,
        link: '/bots',
    })

    return items;
};
