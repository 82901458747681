<template>

  <div class="grid grid-cols-1 gap-5">

    <div class="box p-5">
      <h1 class="text-2xl mb-5">Account Details</h1>
      <user-settings/>
    </div>

    <div class="box p-5">
      <h1 class="text-2xl mb-5">Connected Accounts</h1>

      <div class="grid grid-cols-1 md:grid-cols-3 gap-5">

        <div v-for="account of linkedAccounts" class="box p-2 flex content-center">
          <svg-icon  v-if="typeof account.icon === 'string'" :path="account.icon" type="mdi" size="64px" style="max-width: 64px;"/>
          <component v-else :is="account.icon" :size="64" style="max-width: 64px"/>
          <button v-if="account.profile" class="ml-5 chip flex-grow"><svg-icon :path="mdiTrashCan()" type="mdi" class="inline"/>Disconnect {{ account.profile.displayName ||account.profile.username || account.profile.provider_id }}</button>
          <button @click="account.link" v-else class="ml-5 chip flex-grow"><svg-icon :path="mdiPlus()" type="mdi" class="inline"/>Connect Account</button>
        </div>

      </div>

    </div>

  </div>


</template>

<script>

import {closeModal, openModal, promptModal} from "jenesius-vue-modal";
import EditLoginDetails from "@/components/modals/EditLoginDetails.vue";
import { startRegistration } from '@simplewebauthn/browser';
import DynamicIcon from "@/components/dynamic_icon.vue";
import Grid from "@/components/grid/Grid.vue";
import svgIcon from "@jamescoyle/vue-icon";
import {mdiAccountCard, mdiPassport, mdiPencil, mdiPlus, mdiTrashCan, mdiTwitch, mdiYoutube} from "@mdi/js";
import ConfirmCancel from "@/components/modals/ConfirmCancel.vue";
import DiscordIcon from "@/components/icons/Discord.vue";
import UserSettings from "@/components/settings/user_settings.vue";

export default {
  name: 'UserSettingsAbout',
  components: {UserSettings, DiscordIcon, svgIcon, Grid, DynamicIcon},
  data() {
    return {
      changing_email: false,
    }
  },
  mounted() {
    this.$store.commit('setTitle', 'About')
  },
  methods: {
    mdiPlus() {
      return mdiPlus
    },
    mdiYoutube() {
      return mdiYoutube
    },

    // icons
    mdiPencil() {
      return mdiPencil
    },
    mdiTrashCan() {
      return mdiTrashCan
    },
    mdiTwitch(){
      return mdiTwitch
    },

    logout(){
      location.href = `https://${process.env.VUE_APP_API_HOST}auth/logout`;
    },
    changeUserEmail(e) {
      this.$api.v1.put('/users/' + this.user.id, {
        email: e.srcElement.value,
      }).then(() => {
        this.changing_email = false;
        this.$store.dispatch('checkAuth');
      });
    },
    changeUserAiPrefs(e) {
      this.$api.v1.put('/users/' + this.user.id, {
        setting_allow_ai_classification: e.srcElement.checked,
      }).then(() => {
        this.$store.dispatch('checkAuth');
      });
    },
    async createPassKey() {
      //
      // const response = await this.$api.auth.get('/passkey/setup')
      // let attResp;
      // try {
      //   attResp = await startRegistration(response.data);
      // } catch (error) {
      //   if(error.code === 'ERROR_PASSTHROUGH_SEE_CAUSE_PROPERTY'){
      //     error.message = 'Passkey not supported on device or a passkey is already registered for it.'
      //   }
      //   this.$notify({ type: 'error', text: `${error.code || "Error"}: ${error.message}`, duration: -1})
      //   throw error;
      // }
      //
      // // POST the response to the endpoint that calls
      // const verificationResp = await this.$api.auth.post('/passkey/register', attResp, {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   }
      // });
      //
      // this.$refs.passkeys.refresh()

    },
    async deletePassKey(id) {
      // let result = await promptModal(ConfirmCancel, {
      //   title: 'Delete Passkey',
      //   text: `Are you sure you want to delete passkey "${id}"?`,
      //   confirmText: 'Delete',
      //   cancelText: 'Cancel'
      // });
      //
      // if (result) {
      //   this.$api.v1.delete('/passkeys/' + id).then(() => {
      //     this.$refs.passkeys.refresh()
      //   }).catch((error) => {
      //     this.$notify({
      //       group: 'auth',
      //       title: 'Error',
      //       text: error,
      //       type: 'error'
      //     });
      //   })
      // }
    }
  },
  computed: {
    subscribed(){
      return !!this.$store.state?.user?.subscription?.id
    },
    loggedIn(){
      return !!this.$store.state.user
    },
    user(){
      return this.$store.state.user
    },

    linkedAccounts() {
      return [
        {
          profile: this.user.linked_accounts.find( account => account.provider === 'twitch'),
          icon: mdiTwitch,
          link: function(){
            location.href = `https://${process.env.VUE_APP_API_HOST}auth/twitch/link`
          },
        },
        {
          profile: this.user.linked_accounts.find( account => account.provider === 'discord'),
          icon: DiscordIcon,
          link: function(){
            location.href = `https://${process.env.VUE_APP_API_HOST}auth/discord/link`
          }
        },
        {
          profile: this.user.linked_accounts.find( account => account.provider === 'google'),
          icon: mdiYoutube,
          link: function () {
            location.href = `https://${process.env.VUE_APP_API_HOST}auth/google/link`
          }
        }
      ]

    },
  }
}
</script>
