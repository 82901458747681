<template>

	<BaseModelStyle>

		<h2 class="text-2xl">Creating new Token</h2><br>

		<label class="text-md mt-5">Give your token a recognizable name</label>
		<input type="text" class="form-input rounded-md border-white border-2 block w-full" placeholder="API Token" v-model="name" /><br>

		<label class="text-md">Select your token's expiry date</label>
		<select type="text" class="form-input rounded-md border-white border-2 block w-full text-black" v-model="expiry_mode">
			<optgroup label="Set expiration dates">
				<option value="90">90 Days after token was created</option>
				<option value="30">30 Days after token was created</option>
			</optgroup>
			<optgroup label="Variable expiration dates">
				<option value="-30" selected>30 Days after token was last used</option>
				<option value="-7">7 Days after token was last used</option>
			</optgroup>
		</select><br>

    <h2 class="text-2xl">Token Scopes</h2><br>
    <p>Scopes determine what a token can do on your behalf. Make sure to only select scopes you actually need.</p><br>

    <b>Presets:</b>
    <div class="presets flex flex-row">
        <div class="bg-black bg-opacity-25 p-2 px-4 m-2 chip" v-for="[key, value] of Object.entries(presets)" @click="applyPreset(value)">{{key}}</div>
    </div><br>

		<div class="grid grid-cols-1">
			<div v-for="[key, value] of Object.entries(AllowedScopes)" class="mb-5 bg-black bg-opacity-25 p-5 bg-menu rounded-md">
				<b class="text-xl" @click="toggleAll(key)">{{key}}</b>
				<div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 ">
					<div v-for="scope of value">
						<label class="flex items-center">
							<input type="checkbox" class="form-checkbox rounded-md border-white border-2 disabled:opacity-50 disabled:border-gray-400 disabled:bg-gray-400 scale-125" :disabled="ForcedScopes.includes(scope)" v-model="scopes[scope]">
							<span class="ml-2">{{scope}}</span>
						</label>
					</div>
				</div>
			</div>

      <div v-if="dangerousScopes.length > 0" class="bg-red-500 bg-opacity-25 p-5 bg-menu rounded-md">
        <b class="text-xl">Careful now,</b>
        <p>You've selected some scopes which can be pretty scary if you expose your token. Anyone with this token will able able to:</p>
        <ul>
          <li class="list-item" v-for="scope of dangerousScopes">- {{scope}}</li>
        </ul>
		  </div>
    </div>

    <button class="bg-green-500 bg-opacity-50 p-3 rounded-md mt-5" @click="createToken">Create Token</button>

	</BaseModelStyle>

</template>
<script>
import {Modal} from "jenesius-vue-modal";
import BaseModelStyle from "@/components/BaseModelStyle.vue";
import Vue from "core-js/internals/task";

const scopes = [

  // User
  "user:read",
  "user:read:email",
  "user:read:subscription",
  "user:read:connected_accounts",

  // Schedules
  "schedule:read",
  "schedule:write",
  "schedule:publish",

  // Streaming
  "stream:read",
  "stream:write",
  "stream:publish",

];

const presets = {
  "Stream Key": [
    "stream:read",
    "stream:write",
    "stream:publish",
  ],
  "Everything": [
      ...scopes
  ]
}

export const dangerScopes = {
  // TODO: Add warning for dangerous scopes
}

export default {
	computed: {
    presets(){
      return presets;
    },
		ForcedScopes() {
			return [
				"user:read",
			];
		},
		AllowedScopes() {
			let sections = {};
			for (let scope of scopes) {
				let section = scope.split(":")[0];
				if (!sections[section]) sections[section] = [];
				sections[section].push(scope);
			}
			return sections;
		},
    dangerousScopes(){
      const list = new Set();
      for (let [scope, description] of Object.entries(dangerScopes)) {
        if(this.scopes[scope]){
          list.add(description);
        }
      }
      return Array.from(list);
    }
	},
	components: {BaseModelStyle},
	data() {
		return {
			name: "",
			expiry_mode: "-30",
			scopes: {
				"user:read": true,
			},
		};
	},
	methods: {
		result(value) {
			this.$emit(Modal.EVENT_PROMPT, value);
		},
		change(scope){
      console.log('change', scope);
			if(this.scopes[scope]){
				delete this.scopes[scope];
			}else{
				this.scopes[scope] = true;
			}
		},
    toggleAll(key){
      console.log('toggleAll', key);
      let newValue = null;
      for(let scope of this.AllowedScopes[key]){
        if(this.ForcedScopes.includes(scope)) continue;
        console.log(scope);

        if(newValue === null){
          console.log('newValue is now', !this.scopes[scope]);
          newValue = !this.scopes[scope];
        }

        if(newValue){
          this.scopes[scope] = true;
        }else{
          delete this.scopes[scope];
        }

      }
    },
    applyPreset(preset){
      console.log('applyPreset', preset);
      this.scopes = {};
      for(let scope of preset){
        this.scopes[scope] = true;
      }
    },

    createToken(){

      console.log('createToken', this.name, this.expiry_mode, this.scopes);

      if(this.name.length < 3){
        this.$notify.error('Token name must be at least 3 characters long');
        return;
      }

      if(Object.keys(this.scopes).length === 0){
        this.$notify.error('You must select at least one scope');
        return;
      }

      this.$api.v1.post('tokens', {
        name: this.name,
        expiry_mode: this.expiry_mode,
        scopes: Object.keys(this.scopes),
      }).then((response) => {
        this.result(response.data);
      }).catch((error) => {
        console.log('error', error);
        this.$notify.error('Failed to create token: ' + error?.response?.data?.message);
      });

    }

	}
}
</script>