<template>
  <svg-icon type="mdi" :path="icon" class="inline" />
</template>

<script>

import svgIcon from "@jamescoyle/vue-icon";
import {mdiUpload, mdiImport, mdiMagnify, mdiPlus, mdiGroup, mdiHelp} from "@mdi/js";

export default {
  name: 'DynamicIcon',
  components: {
    svgIcon
  },
  props: {
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    icon(){
      console.log('icon', this.type);
      return this[this.type] ?? this.mdiHelp;
    },
    mdiHelp() {
      return mdiHelp;
    },
    mdiPlus() {
      return mdiPlus;
    },
    mdiGroup() {
      return mdiGroup;
    },
    mdiUpload() {
      return mdiUpload;
    },
    mdiImport() {
      return mdiImport;
    },
    mdiMagnify() {
      return mdiMagnify;
    }
  }
}
</script>