<template>

  <div class="box bg-gray-900 text-white p-10 max-h-full overflow-y-scroll" style="max-height: 80vh;">
    <slot/>
  </div>

</template>
<script>
export default {
  name: 'BaseModelStyle'
}
</script>