<template>

  <div class="recent-events">
    <div class="flex justify-end">
      <div class="flex-grow">
        <h1 class="text-2xl">Recent Events</h1>
        <p>Records of what's happening on your channel.</p><br>
      </div>
      <div>
        <router-link to="/streamsuite/events" class="chip text-right">View all history</router-link>
      </div>
    </div>
    <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-2 flex-nowrap overflow-hidden">
      <StreamEvent v-for="item in recent_actions" :key="item.id" :log="item" />
    </div>
  </div>

</template>

<script>

import StreamEvent from "@/components/streamsuite/streamevent.vue";

export default {
  name: 'StreamEventList',
  components: {
    StreamEvent
  },
  data: () => ({
    recent_actions: []
  }),
  mounted() {
    // Add event listener when component mounts
    window.addEventListener('dispatch:streamsuite_stream_event', this.handleCustomSocketEvent);
    this.getRecentActions();

  },
  beforeUnmount() {
    // Remove event listener before the component is unmounted to prevent memory leaks
    window.removeEventListener('dispatch:streamsuite_stream_event', this.handleCustomSocketEvent);
  },
  methods: {
    handleCustomSocketEvent(event) {
      // Handle the custom socket event
      console.log('Custom socket event received:', event);
      this.recent_actions.unshift(event.detail.obj);

      if(this.recent_actions.length > 6){
        this.recent_actions.pop();
      }

    },
    async getRecentActions() {
      const response = await this.$api.v1.get(`streamsuite/streamevents?limit=6`);
      if(response?.data?.data){
        this.recent_actions = response.data.data;
      }
    }
  }

}
</script>