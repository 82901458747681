<template>
  <div class="live-background">
    <div v-for="(orb, index) in orbs" :key="index" :class="`orb`" :style="orb.style"></div>
  </div>
</template>

<script>
export default {
  name: 'LiveBackground',
  data() {
    return {
      orbs: [],
    };
  },
  methods: {
    generateOrb() {

      const size = Math.random() * (120 - 40) + 40 * 50; // Orb size between 40px and 120px
      const animationDuration = Math.max(Math.random() * 120, 60); // Animation duration between 60s and 120s for slow movement
      const orb = {
        style: {
          left: (Math.random() * window.innerWidth - size/2) + 'px',
          top: (Math.random() * window.innerHeight - size/2) + 'px',
          width: `${size}px`,
          height: `${size}px`,
          animationDuration: `${animationDuration}s`,
          animationDelay: `-${Math.random() * 30}s`, // Start animations at different times
        },
      };
      this.orbs.push(orb);
    },
  },
  created() {
    for (let i = 0; i < 5; i++) {
      this.generateOrb();
    }
    setInterval(() => {
      this.generateOrb();
      if (this.orbs.length > 10) {
        this.orbs.shift();
      }
    }, 10000); // Add a new orb every 10 seconds to manage performance
  },
};
</script>

<style scoped>
.live-background {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: -998;
}
.orb {
  position: absolute;
  background: radial-gradient(circle, rgba(192, 44, 192, 0.2) 0%, transparent 70%);
  opacity: 0;
  animation: fadeInOut 10s infinite ease-in-out, moveRandomly 10s infinite alternate;
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0; }
  50% { opacity: 0.8; }
}

@keyframes moveRandomly {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(20vw, 20vh);
  }
}
</style>
