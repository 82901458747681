<template>

  <p class="card inline" style="width: 200px;">blablablabla</p>
</template>

<script>

export default {
  name: 'HomeView',
  components: {
  },
  mounted() {
    this.$store.commit('setTitle', 'Home')
  }
}
</script>
