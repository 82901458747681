<template>

  <LiveBackground/>
  <div class="dynamic-gradient opacity-0 transition-opacity" ref="bg-cursor-gradient"></div>

  <commandbar :active="commandbar" />

  <widget-container-modal />

  <notifications position="top center"
                 :max="3"
                 :width="400"/>

  <responsive :min="$sm">
    <navbar/>
  </responsive>

  <div class="content p-10">
    <router-view/>
  </div>


  <responsive :max="$sm">
    <br><br><br><br>
    <navbar-m/>
  </responsive>

</template>

<script>
import navbar from './components/layout/navbar.vue'
import navbarM from "@/components/layout/navbar.m.vue";
import Responsive from "@/components/screen/Responsive.vue";
import Profile from "@/views/UserSettings.vue";
import {container} from "jenesius-vue-modal";
import Commandbar from "@/components/ui/Commandbar.vue";
import LiveBackground from "@/components/ui/LiveBackground.vue";
export default {
  name: 'App',
  mounted(){

    console.log(this.$refs);

    const cursorEl = this.$refs['bg-cursor-gradient']
    document.addEventListener('mousemove', function(e) {
      cursorEl.classList.add('opacity-100');
      cursorEl.style.left = `${e.clientX - cursorEl.clientWidth/2}px`;
      cursorEl.style.top = `${e.clientY - cursorEl.clientHeight/2}px`;
    });


    // Fetch application data from the API
    this.$store.dispatch('getApplicationStatus');
    this.$store.dispatch('checkAuth');
    this.$store.dispatch('connectSocket');

    // Register CMD bar shortcut
    let lastShift = 0;
    document.addEventListener('keydown', (e) => {

      // dont trigger on input fields
      if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') return;

      if(e.key === 'Shift' ){
        if((Date.now() - lastShift) < 250) {
          this.$store.commit('setCommandbar', !this.$store.state.commandbar.active)
          console.log('Commandbar', 'Shortcut pressed')
          lastShift = 0;
        }else{
          lastShift = Date.now();
        }
      }
    });

  },
  components: {
    LiveBackground,
    Commandbar,
    Profile,
    Responsive,
    navbarM,
    navbar,
    WidgetContainerModal: container
  },
  computed: {
    commandbar(){
      return this.$store.state.commandbar.active;
    },
  }
}
</script>

<style lang="less">
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

  /* global site theme */
  html {
    @apply text-white;
    @apply bg-black;
  }

  .card {
    @apply bg-black;
    @apply bg-opacity-50;
    @apply rounded-md;
    @apply p-4;
    @apply mb-4;

    @apply backdrop-blur-2xl;

    @apply border-2;
    @apply border-white;
    @apply border-opacity-75;

  }

  .chip {
    @apply bg-transparent;
    @apply border-2;
    @apply border-gray-500;
    @apply rounded-lg;
    @apply px-4;
    @apply py-1;
    @apply transition-colors;
    @apply duration-100;
    @apply cursor-pointer;
  }

  .box {
    // bg-black bg-opacity-25 p-1 mb-1 bg-menu rounded-md flex gap-4 border-transparent border-2 hover:border-white transition-colors justify-start text-left
    @apply bg-black;
    @apply bg-opacity-25;
    @apply rounded-sm;
    @apply border-transparent;
    @apply border-2;
    @apply transition-colors;
  }

  .cursor-pointer.box:hover {
    @apply border-white;
  }

  .chip:hover:not(.disabled):not(.readonly) {
  @apply bg-transparent;
  @apply border-2;
  @apply border-gray-700;
  @apply bg-gray-800;
  }

  .chip.active:not(.disabled):not(.readonly) {
    @apply bg-transparent;
    @apply border-2;
    @apply bg-white;
    @apply text-black;
  }

  .chip.disabled {
    @apply opacity-25;
  }

  .chip > input {
    @apply border-none;
    @apply outline-none;
    @apply bg-transparent;
    @apply text-white;
  }

	textarea.chip {
		border-radius: 10px;
	}

  .tooltip {
    @apply text-black;
    @apply bg-white;
    @apply rounded-md;
    @apply p-2;
    @apply text-sm;
    @apply z-50;
  }

  kbd {
    @apply bg-white;
    @apply text-black;
    @apply rounded-md;
    @apply px-2;
    @apply py-1;
  }

  .arg {
    @apply bg-black;
    @apply rounded-md;
    @apply px-2;
    @apply py-1;
    @apple border-2;
    @apply text-sm;
    @apply text-red-600;
    @apply border-red-900;
  }

}

.dynamic-gradient {
  width: 2500px;
  height: 2500px;
  background: radial-gradient(circle closest-side, rgba(255, 255, 255, 0.025) 0%, transparent 85%) no-repeat;
  background-color: transparent; /* Tailwind color gray-900 for dark theme */

  position: fixed;
  top: 0;
  left: 0;

  z-index: -999;
  pointer-events: none;

}

.tooltip {
  display: none;
  position: absolute;
  z-index: 1000;
  transform: translateX(-25%) translateY(50%);
}

.modal-container{
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 9999;
  background-color: rgba(25, 25, 25, 0.7);
}

.avatar {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

@keyframes loading {
  0% {
    opacity: 1;
    filter: brightness(1);
  }
  50% {
    opacity: 0.5;
    filter: brightness(1.2);
  }
  100% {
    opacity: 1;
    filter: brightness(1);
  }
}

.loading {
  animation: ease infinite loading 2s;
}

html, body {
  height: 100%;
}

input {
  height: 36px;
  line-height: 36px;
  padding: 0 10px;
  color: black;
}

</style>

