<template>
  <div class="box p-2">
    <div class="flex gap-4 justify-start text-left mb-1">
      <div class="flex flex-col items-center">
        <img :src="stream.game.box_art_url" class="w-auto h-40 rounded-sm" />
      </div>
      <div class="flex flex-col">
        <div class="">
          <p class="text-lg bold">
            <svgIcon :path="icon" type="mdi" class="inline mr-2" />
            {{ stream.title }}
          </p>
          <p class="">{{ stream.game.title }}</p>
          <p>{{ render_time }}</p>
          <p class="whitespace-pre-wrap truncate">{{stream.description}}</p>
        </div>

        <div class="mt-4 flex gap-5">
          <svgIcon :path="mdiTwitch()" type="mdi" class="inline" :class="styleIconAvailableOn('twitch')" />
          <svgIcon :path="mdiYoutube()" type="mdi" class="inline" :class="styleIconAvailableOn('youtube')" />
          <DiscordIcon class="inline" :class="styleIconAvailableOn('discord')" />
          <svgIcon :path="mdiTwitter()" type="mdi" class="inline" :class="styleIconAvailableOn('twitter')" />
        </div>

      </div>

    </div>
  </div>


</template>

<script>

import svgIcon from "@jamescoyle/vue-icon";
import {
  mdiCalendar, mdiTwitch, mdiTwitter, mdiYoutube
} from "@mdi/js";
import {DateTime} from "luxon";
import DiscordIcon from "@/components/icons/Discord.vue";

export default {
  name: 'StreamScheduleItem',
  methods: {
    mdiTwitter() {
      return mdiTwitter
    },
    mdiYoutube() {
      return mdiYoutube
    },
    mdiTwitch() {
      return mdiTwitch
    },
    styleIconAvailableOn(service){

      let isAvailable = false;
      switch (service){
        case 'twitch':
          isAvailable = this.stream.twitch_schedule_id !== null;
          break;
        case 'youtube':
          isAvailable = this.stream.youtube_video_id !== null;
          break;
        case 'discord':
          isAvailable = this.stream.discord_event_id !== null;
          break;
        case 'twitter':
          isAvailable = this.stream.twitter_post_id !== null;
          break;
      }

      return isAvailable ? 'text-green-500' : 'text-gray-500';

    }
  },
  components: {
    DiscordIcon,
    svgIcon
  },
  props: {
    stream: {
      type: Object,
      required: true,
      default: {
        user: {
          id: 0,
          type: 'user'
        },
        title: 'ban',
        description: 'twitch',
        time: '2021-01-01T00:00:00',
        game: {
          id: '0',
          title: 'twitch',
          box_art_url: 'https://picsum.photos/285/380'
        },
        twitch_schedule_id: null,
        youtube_video_id: null,
        discord_event_id: null,
        twitter_post_id: null,
      }
    }
  },
  computed: {
    icon(){
      return mdiCalendar;
    },
    render_time(){
      return DateTime.fromISO(this.stream.time).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY) + ' ' + DateTime.fromISO(this.stream.time).toLocaleString(DateTime.TIME_SIMPLE);
    }
  }
}
</script>