import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Docs from "@/views/api/docs.vue"
import Authentication from "@/views/api/topics/authentication.vue";
import Errors from "@/views/api/topics/errors.vue";
import Welcome from "@/views/api/topics/welcome.vue";
import Lists from "@/views/api/topics/collections.vue";
import LoginView from "@/views/auth/login.vue";
import UserSettings from "@/views/UserSettings.vue";
import UserSettingsAbout from "@/views/user_settings/About.vue";
import UserSettingsApi from "@/views/user_settings/Api.vue";
import UserSettingsBilling from "@/views/user_settings/Billing.vue";
import StreamSuiteList from "@/views/streamsuite/overview.vue"
import StreamSuiteEventList from "@/views/streamsuite/list_events.vue"
import Endpoint from "@/views/api/topics/endpoint.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/settings',
    name: 'settings',
    component: UserSettings
  },
  {
    path: '/settings',
    name: 'profileSettings',
    component: UserSettings,
    children: [
      {
        path: '/settings/about',
        name: 'profileSettingsAbout',
        component: UserSettingsAbout,
      },
      {
        path: '/settings/api',
        name: 'profileSettingsApi',
        component: UserSettingsApi,
      },
      {
        path: '/settings/billing',
        name: 'profileSettingsBilling',
        component: UserSettingsBilling,
      }
    ]
  },
  {
    path: '/streamsuite',
    name: 'streamsuite',
    component: StreamSuiteList
  },
  {
    path: '/streamsuite/events',
    name: 'streamsuite events',
    component: StreamSuiteEventList
  },
  {
    path: '/api/docs',
    name: 'Api Docs',
    component: Docs,
    children: [

      // index
      {
        path: '',
        name: 'Api Docs - Index',
        redirect: '/api/docs/intro',
      },

      // topics
      {
        path: 'intro',
        name: 'Api Docs - Intro',
        component: Welcome,
      },
      {
        path: 'auth',
        name: 'Api Docs - Auth',
        component: Authentication,
      },
      {
        path: 'collections',
        name: 'Api Docs - Collections',
        component: Lists,
      },
      {
        path: 'errors',
        name: 'Api Docs - Errors',
        component: Errors,
      },

      // endpoints
      { path: ':routePart+', name: "Api Docs - Endpoint", component: Endpoint}

    ]
  }
]

export default function router(state){

  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  });

  router.beforeEach((to, from) => {
    state.commit('setCommandbarContextCommands', []);
  });

  return router;
}
