<template>

  <div v-if="!user">
    <h2 class="text-2xl">Login Required</h2>
    <p>You need to be logged in to use vods.</p>
  </div>

  <div v-else>

    <div class="box w-full p-10">

      <grid ref="eventsgrid" endpoint="streamsuite/streamevents" mode="grid grid-flow-row-dense grid-cols-1">
        <template #items="props">
          <div v-for="item in props.list" :key="item.id">
            <StreamEvent :log="item" />
          </div>
        </template>
      </grid>

    </div>


  </div>


</template>

<script>
import svgIcon from "@jamescoyle/vue-icon";
import DynamicIcon from "@/components/dynamic_icon.vue";
import StreamEventList from "@/components/streamsuite/stream_event_list.vue";
import StreamEvent from "@/components/streamsuite/streamevent.vue";
import Grid from "@/components/grid/Grid.vue";

export default {
  name: 'VodList',
  components: {
    Grid,
    StreamEvent,
    StreamEventList,
    DynamicIcon,
    svgIcon,
  },
  async mounted() {

    this.$store.commit('setTitle', 'Stream Suite');

  },
  methods: {
  },
  computed:{
    user(){
      return this.$store.state.user
    },
  }
}
</script>
<style scoped lang="less">

@import '@/assets/less/theme.less';

</style>
