<template>

  <div class="box flex gap-4 justify-start text-left mb-1" :class="{ 'mt-10': log.action === 'stream_end'}">

    <svg-icon type="mdi" :path="icon" class="inline text-2xl" style="width: 25px; margin-left: 5px; margin-top: 5px;" />
    <div>

      <template v-if="log.data.name">
        <span class="text-red-500 text-xl">{{log.data.name}}</span><br>
      </template>

      <template v-if="log.action === 'raid'">
        raided {{log.data.event_name}} with {{log.data.viewers}} viewers
      </template>


      <template v-if="log.action === 'ban'">
        was banned by <span class="text-red-500">{{log.data.mod_name}}</span>
      </template>

      <template v-else-if="log.action === 'unban'">
        was unbanned by <span class="text-red-500">{{log.data.mod_name}}</span>
      </template>

      <template v-else-if="log.action === 'stream_start'">
        Stream <span class="text-red-500">{{log.data.title}}</span> was started
      </template>

      <template v-else-if="log.action === 'stream_end'">
        Stream ended
      </template>

      <template v-else-if="log.action === 'follow'">
        followed
      </template>

      <template v-else-if="log.action === 'subscription'">
        subscribed at tier {{log.data.tier / 1000}}
      </template>

      <template v-else-if="log.action === 'gift'">
        gifted {{log.data.amount}} tier {{log.data.tier / 1000}} subs
      </template>

      <template v-else-if="log.action === 'redeem'">
        redeemed {{log.data.reward_name}}
      </template>

      <template class="text-gray-400 text-sm ml-auto block">
        {{date}}
      </template>
    </div>


  </div>

</template>

<script>

import svgIcon from "@jamescoyle/vue-icon";
import {
  mdiUpload,
  mdiImport,
  mdiMagnify,
  mdiPlus,
  mdiGroup,
  mdiHelp,
  mdiAccountRemove,
  mdiUndo,
  mdiPlay,
  mdiStop,
  mdiAccountPlus,
  mdiCurrencyUsd,
  mdiGift,
  mdiCircle,
  mdiStar,
  mdiCheckboxMultipleMarkedCircleOutline,
  mdiFlag
} from "@mdi/js";

export default {
  name: 'StreamEvent',
  components: {
    svgIcon
  },
  props: {
    log: {
      type: Object,
      required: true,
      default: {
        user: {
          id: 0,
          type: 'user'
        },
        action: 'ban',
        service: 'twitch',
        triggered_by: 0,
        data: {}
      }
    }
  },
  computed: {
    icon(){
      switch(this.log.action){

        case 'stream_start': return mdiPlay;
        case 'stream_end': return mdiStop;

        case 'ban': return mdiAccountRemove;
        case 'unban': return mdiUndo;

        case 'raid': return mdiFlag;

        case 'follow': return mdiAccountPlus;
        case 'subscription': return mdiStar;
        case 'gift': return mdiGift;
        case 'redeem': return mdiCheckboxMultipleMarkedCircleOutline;
      }
      return mdiHelp;
    },
    date(){
      return new Date(this.log.createdon).toLocaleString();
    }
  }
}
</script>